import React, { useState, useEffect } from 'react'
import { BsTwitterX } from 'react-icons/bs'
import { CgGlobeAlt } from 'react-icons/cg'
import { FaDiscord } from 'react-icons/fa'
import { FaTelegramPlane } from 'react-icons/fa';
import { AiFillLinkedin } from 'react-icons/ai';
import Flip from 'react-reveal/Flip'

export default function WorkedCard() {
  const [workedData, setWorkedData] = useState([]);

  const importImage = async (imageName) => {
    if (!imageName) {
      console.error('Filename is undefined, skipping import');
      return { default: '/path/to/default/image.png' }; // path to a default placeholder image
    }
    try {
      const module = await import(`../assets/workedIcon/${imageName}`);
      return module;
    } catch (e) {
      console.error('Error loading image:', imageName, e);
      return { default: '/path/to/default/image.png' }; // path to a default placeholder image
    }
  };

  useEffect(() => {
    const initialData = [
      {
        filename: 'moemate.svg',
        text: 'Moemate is the ecosystem for building, incubating, hosting, operating and trading ai agents',
        twitter: 'https://x.com/MoemateAI',
        website: 'https://moemate.io/',
        telegram: 'https://t.me/nebulamoemate',
        discord: 'https://discord.gg/Xa5tfypYVm',
        reddit: 'https://www.reddit.com/r/moemateapp',
        linktree: 'https://linktr.ee/moemate',
      },
      {
        filename: "kindred.png",
        text: "World's First IP-Powered Agentic AI Platform | Bringing Iconic IPs to Life as Emotionally Intelligent Agents",
        twitter: 'https://x.com/kindred_ai',
        website: 'https://www.kindredlabs.ai/',
        telegram: 'https://t.me/Kindred_AI',
        discord: 'https://discord.gg/kindred-ai'
      },
      {
        filename: 'kip.png',
        text: 'Web3 Base Layer for AI',
        twitter: 'https://twitter.com/KIPprotocol',
        website: 'https://www.kip.pro/',
        discord: 'https://discord.gg/kipprotocol',
      },
      {
        filename: "aethir.png",
        text: 'Building Scalable Decentralised Cloud Infrastructure (DCI) for Gaming & AI.',
        twitter: 'https://twitter.com/AethirCloud',
        website: 'https://www.aethir.com/',
        discord: 'http://discord.gg/aethircloud',
        youtube: 'https://www.youtube.com/@AethirCloud',
      },
      {
        filename: 'nuklai.png',
        text: 'The Layer 1 for Data Collaboration, Sharing and Monetization that powers AI and LLMs through #SmartData.',
        twitter: 'https://twitter.com/NuklaiData',
        website: 'https://www.nukl.ai/',
        discord: 'https://discord.com/invite/nuklai',
        linkedin: 'https://www.linkedin.com/company/nuklai/',
        telegram: 'https://t.me/NuklaiOfficial'
      },
      {
        filename: "magnetai.png",
        text: 'The First ModelFi Protocol',
        twitter: 'https://twitter.com/magnetaixyz',
        website: 'https://magnetai.xyz/',
      },
      {
        filename: 'kasu.svg',
        text: 'The most risk-optimised RWA Yields in all of DeFi',
        twitter: 'https://twitter.com/KasuFinance',
        website: 'https://kasu.finance/',
        discord: 'https://discord.gg/jm2V7vUg99',
        linkedin: 'https://www.linkedin.com/company/kasu-finance/',
        telegram: 'https://t.me/KASU_Fi'
      },
      {
        filename: 'credbull.png',
        text: 'Credbull is the first licensed Private Credit DeFi platform offering structured high fixed yields.',
        twitter: 'https://twitter.com/credbullDeFi',
        website: 'https://credbull.io/',
        discord: 'https://discord.com/invite/yPGanb6t4f',
        telegram: 'https://t.me/CredbullMain'
      },
      {
        filename: 'arkefi.svg',
        text: 'Invest into Real-World Assets | Transforming Non-Bankables into Liquid Assets',
        twitter: 'https://twitter.com/_Arkefi',
        website: 'https://www.arkefi.com/',
        telegram: 'https://t.me/Arkefiglobal',
        discord: 'https://discord.com/invite/7nNATDXFH7',
        linkedin: 'https://www.linkedin.com/company/arkefi-ag/'
      },
      {
        filename: 'kintsu.webp',
        text: 'Next-gen liquid staking infrastructure',
        twitter: 'https://twitter.com/Kintsu_xyz',
        website: 'https://kintsu.xyz/',
        discord: 'https://discord.gg/P2BGa3wG4s'
      },
      {
        filename: 'nibiru.svg',
        text: 'The Web3 hub ushering in the next era of money',
        twitter: 'https://twitter.com/NibiruChain',
        website: 'https://nibiru.fi/',
        discord: 'https://discord.gg/nibirufi',
        linkedin: 'https://www.linkedin.com/company/nibiruchain',
        telegram: 'https://t.me/nibiruchain',
        youtube: 'https://www.youtube.com/@nibiruchain'
      },
      {
        filename: 'velar.png',
        text: 'DeFi Liquidity Protocol on Bitcoin',
        twitter: 'https://twitter.com/VelarBTC',
        website: 'https://www.velar.co/',
        discord: 'https://discord.gg/velar',
        telegram: 'https://t.me/velarofficial'
      },
      {
        filename: "bevm.png",
        text: 'BEVM is a decentralized EVM-compatible #BTClayer2 based on #Taproot, and using $BTC as gas. BEVM = $BTC EVM + $BTC to VM + BEVM-Stack',
        twitter: 'https://twitter.com/BTClayer2',
        website: 'https://www.bevm.io/',
        discord: 'https://discord.com/invite/AGcRqkTBAV'
      },
      {
        filename: 'shrapnel.png',
        text: 'The 1st moddable extraction shooter game, built by a unique team of BAFTA & Emmy Award-winning talent, using UE5 & blockchain.',
        twitter: 'https://twitter.com/playShrapnel',
        website: 'https://www.shrapnel.com/',
        discord: 'https://discord.com/invite/shrapnel',
        linkedin: 'https://www.linkedin.com/company/shrapnel-studio'
      },
      {
        filename: 'gunzilla.svg',
        text: 'Developers of the highly anticipated cyberpunk battle royale',
        twitter: 'https://twitter.com/GunzillaGames',
        website: 'https://gunzillagames.com',
        linkedin: 'https://www.linkedin.com/company/gunzilla-games'
      },
      {
        filename: 'qorpo.svg',
        text: 'Gaming Democratized. The Platform That Takes You One Token Closer. Explore, Craft, Own ,Player-Driven  $QORPO',
        twitter: 'https://twitter.com/QORPOworld',
        website: 'https://qorpo.world/',
        discord: 'https://discord.com/invite/qorpoworld',
        linkedin: 'https://sk.linkedin.com/company/qorpoworld',
        telegram: 'https://t.me/QORPOworld'
      },
      {
        filename: 'readygg.png',
        text: 'Making infrastructure for the Web3 Games & Dev creator economy📱⁣ Join the Ecosystem 💜⁣  #READYggSetGameOn #READYgg #web3gaming',
        twitter: 'https://twitter.com/TheReadyGames',
        website: 'https://ready.gg/homepage/',
        discord: 'https://discord.gg/mbnbc2Hy2U',
        linkedin: 'https://www.linkedin.com/company/ready-makers-inc',
        telegram: 'https://t.me/readygamesofficial'
      },
      {
        filename: 'elixir.png',
        text: 'Powering A New Gaming Age - $ELIX | Backed by @SquareEnix & @SolanaFndn',
        twitter: 'https://twitter.com/Elixir_Games',
        website: 'https://elixir.games/',
        discord: 'https://ln.onl/discord-elixir',
        linkedin: 'https://ln.onl/linkedin-elixir',
        youtube: 'https://ln.onl/youtube-elixir'
      },
      {
        filename: "somo.png",
        text: 'Transmedia IP conquering gaming, entertainment and collectibles. An official @portalcoin launchpad partner',
        twitter: 'https://twitter.com/playsomo',
        website: 'https://somo.games/',
        discord: 'https://discord.com/invite/SOMO'
      },
      {
        filename: "lingo.png",
        text: 'Gamified, RWA-powered rewards token for the next billion in web3.',
        twitter: 'https://twitter.com/lingocoins',
        website: 'https://lingocoin.io/',
        discord: 'https://discord.com/invite/lingo',
      },
      {
        filename: "monadpad.svg",
        text: 'Leading the way as the first launchpad on @monad_xyz',
        twitter: 'https://twitter.com/MonadPad',
        website: 'https://www.monadpad.xyz/',
        telegram: 'https://t.me/MonadPad'
      }
    ];

  Promise.all(initialData.map(item => importImage(item.filename)))
    .then(images => {
      const updatedWorkedData = images.map((img, index) => ({
        icon: img.default,
        ...initialData[index]
      }));
      setWorkedData(updatedWorkedData);
    });
  }, []);

  const [currentCard, setCurrentCard] = useState(-1);

  const activeCard = (id) => {
    setCurrentCard(currentCard !== id ? id : -1);
  };

  return (
    <div className='w-full flex flex-wrap gap-8 pt-16 pb-16 justify-center'>
      {workedData.map((item, index) => (
        <div key={index} className='inline-block'>
          {currentCard !== index ? (
            <Flip right>
              <div
                onClick={() => activeCard(index)}
                className='relative rounded-lg transition ease-in duration-200 cursor-pointer'
              >
              <div className='w-[256px] h-[190px] pt-3 px-4 pb-10 bg-white my-auto rounded-lg'>
                <img
                  src={item.icon}
                  alt='Project Logo'
                  className='justify-center items-center object-contain w-full h-full p-2'
                />
                </div>
                <div className='flex flex-row absolute bottom-0 text-white w-full rounded-b-md p-2 border-t-[1px] border-gray-200 gap-3 bg-blue-500 justify-center items-center'>
                  {item.twitter && (
                    <a
                      href={item.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-[14px] text-white hover:text-[#56CCF2]'
                      aria-label='Twitter'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <BsTwitterX />
                    </a>
                  )}
                  {item.website && (
                    <a
                      href={item.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-[20px] text-white hover:text-[#56CCF2]'
                      aria-label='Website'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CgGlobeAlt />
                    </a>
                  )}
                  {item.discord && (
                    <a
                      href={item.discord}
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-[20px] text-white hover:text-[#56CCF2]'
                      aria-label='Discord'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaDiscord />
                    </a>
                  )}
                  {item.telegram && (
                    <a
                      href={item.telegram}
                      className='text-[20px] text-white hover:text-[#56CCF2]'
                      aria-label='Telegram'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaTelegramPlane />
                    </a>
                  )}
                  {item.linkedin && (
                    <a
                      href={item.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-[20px] text-white hover:text-[#56CCF2]'
                      aria-label='Linkedin'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <AiFillLinkedin />
                    </a>
                  )}
                  {/* Add other social icons here as needed */}
                </div>
              </div>
            </Flip>
          ) : (
            <div
              onClick={() => activeCard(index)}
              className='inline-block relative rounded-lg transition ease-in duration-200 drop-shadow-white cursor-pointer'
            >
              <Flip left>
                <p className='text-[15px] text-white w-[256px] h-[190px] pt-3 px-4 pb-10 my-auto flex-col flex font-semibold justify-center items-center rounded-lg bg-[#303030]'>
                {item.text}
                </p>
                <div className='flex flex-row fixed bottom-0 text-white w-full rounded-b-md p-2 border-t-[1px] border-gray-200 gap-3 bg-[#ffffff] justify-center items-center'>
                {item.twitter && (
                  <a
                    href={item.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='text-[14px] text-black hover:text-[#56CCF2]'
                    aria-label='Twitter'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BsTwitterX />
                  </a>
                )}
                {item.website && (
                  <a
                    href={item.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='text-[20px] text-black hover:text-[#56CCF2]'
                    aria-label='Website'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CgGlobeAlt />
                  </a>
                )}
                {item.discord && (
                  <a
                    href={item.discord}
                    className='text-[20px] text-black hover:text-[#56CCF2]'
                    aria-label='Discord'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FaDiscord />
                  </a>
                )}
                {item.telegram && (
                    <a
                      href={item.telegram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-[20px] text-black hover:text-[#56CCF2]'
                      aria-label='Telegram'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FaTelegramPlane />
                    </a>
                  )}
                {item.linkedin && (
                  <a
                    href={item.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='text-[20px] text-black hover:text-[#56CCF2]'
                    aria-label='Linkedin'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <AiFillLinkedin />
                  </a>
                )}
                </div>
              </Flip>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

